
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/LifeSkill2023/2024/AluminiTalk/1.jpg';
// import p2 from 'assests/Photos/LifeSkill2023/2024/AluminiTalk/2.jpg';
// import p3 from 'assests/Photos/LifeSkill2023/2024/AluminiTalk/3.jpg';
// import p4 from 'assests/Photos/LifeSkill2023/2024/AluminiTalk/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../LifeSkill2023/Sidebar2024';
import Archive2022 from '../LifeSkill2023/Archive2022';

const AluminiTalk2024 = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  
  useEffect(() => {
    fetch('/config.json') // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/events-activities/life-skill/aluminitalk-2024/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/life-skill/aluminitalk-2024/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/life-skill/aluminitalk-2024/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/life-skill/aluminitalk-2024/4.webp`;


  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      ALUMNI SESSION 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10,11 & 12      Date: 1 June 2024 
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On 1 June 2024, students from grades 10 to 12 at National Public School Yeshwanthpur were addressed by our alumni, Mr S V  Venkata Sai Vishweshwar, who is currently pursuing B. Tech., in Biotechnology at IIT Madras. Mr Venkat shared invaluable insights on IIT preparation that included Mars Rover project and inspired juniors with his personal experiences.   
        <br></br>
        The session effectively covered study strategies, time management tips and the importance of resilience. His speech perfectly aligned with our school's value system as he advised students to restrict social media, focus on their goals and view every stress as a challenge to be embraced. His gratitude to his alma mater was evident as he patiently answered questions with utter transparency and enthusiasm. The juniors were left motivated and better equipped to tackle the challenges ahead. 
        <br></br>
        Mr Venkat was full of humility and made a lasting impression on the juniors. His approachable demeanour made the session both informative and engaging.  

        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Appreciate each other’s strengths and learn from shared experiences”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 250 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default AluminiTalk2024;